<template>
  <!-- Main content -->
  <section class="content">
    <!-- 搜索 -->
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="用户uid：">
          <el-input v-model="search_id" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="资产类型：">
            <el-select v-model="search_type" placeholder="">
              <el-option value="" label="全部"></el-option>
              <el-option v-for="item in typeLists" :label="item.name" :value="item.type"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="seach(1)">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加按钮 -->
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add"
        >添加</el-button
      >
    </div>
    <!-- 表单 -->
    <el-table
      v-loading="loading"
      :data="blackList"
      border
      style="width: 100%"
    >
      <!-- <el-table-column
        fixed
        label="编号"
        prop="id"
        width="50"
      ></el-table-column> -->
<el-table-column fixed label="用户id" prop="user_id">
  <template slot-scope="item">
    {{item.row.user_id}}({{item.row.user_key}})
  </template>
</el-table-column>

<el-table-column label="资产类型">
    <template slot-scope="item">
    {{item.row.type | type}}
  </template>
</el-table-column>
<el-table-column label="充值类型">
    <template slot-scope="item">
    {{item.row.status | status}}
  </template>
</el-table-column>
<el-table-column label="充值金额" prop="money"></el-table-column>
<el-table-column label="充值原因" prop="remark"></el-table-column>
<el-table-column label="充值时间">
    <template slot-scope="item">
    {{item.row.create_time | dateTime}}
  </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" :current-page="page">
</el-pagination>
<!-- 修改添加弹窗 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal="false">
    <el-form>
        <el-form-item label="用户user_id：">
            <el-input v-model="user_id" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="资产类型：">
            <el-radio-group v-model="type">
                <el-radio v-for="item in typeList" :label="item.type">{{item.name}}</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="充值类型：">
            <el-radio-group v-model="status">
                <el-radio label="1">增加</el-radio>
                <el-radio label="2">扣除</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="充值金额：">
            <el-input v-model="amount" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="充值理由：">
            <el-input v-model="remark" type="textarea" placeholder=""></el-input>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem("common"));
    import * as api from "@/config/api";
    export default {
        name: "maintenanceRecharge",
        data() {
            return {
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,

                dialog: false,
                blackList: [],

                typeList: [{
                    type: '1',
                    name: '余额'
                }, {
                    type: '3',
                    name: '免单点'
                }, {
                    type: '2',
                    name: '积分'
                }],
                typeLists: [{
                    type: '1',
                    name: '余额'
                }, {
                    type: '2',
                    name: '免单点'
                }, {
                    type: '3',
                    name: '积分'
                }],

                search_id: "",
                search_type: '',

                user_id: "",
                type: '',
                amount: '',
                remark: '',
                status: '',

                is_ok: false,
            };
        },
        filters: {
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            },
            type(val) {
                switch (Number(val)) {
                    case 1:
                        return '余额'
                        break;
                    case 2:
                        return '免单点'
                        break;
                    case 3:
                        return '积分'
                        break;
                    default:
                        break;
                }
            },
            status(val) {
                switch (Number(val)) {
                    case 1:
                        return '增加'
                        break;
                    case 2:
                        return '扣除'
                        break;
                    default:
                        break;
                }
            }
        },
        watch: {},
        mounted() {
            this.userRechargeList();
        },
        methods: {

            getTime(time) {
                let Time = new Date(time * 1000);
                return `${Time.getFullYear()}-${Time.getMonth() + 1 < 10 ? "0" + (Time.getMonth() + 1) : Time.getMonth() + 1}-${Time.getDate() < 10 ? "0" + Time.getDate() : Time.getDate()} ${Time.getHours() < 10 ? "0" + Time.getHours() : Time.getHours()}:${Time.getMinutes() < 10 ? "0" + Time.getMinutes() : Time.getMinutes()}:${Time.getSeconds() < 10 ? "0" + Time.getSeconds() : Time.getSeconds()}`
            },
            // 获取列表
            userRechargeList(page, size) {
                api.userRechargeList({
                        page: page ? page : this.page,
                        pagesize: size ? size : this.pagesize,
                        user_id: this.search_id,
                        type: this.search_type
                    },
                    (res) => {
                        this.blackList = res.data.list;
                        this.total = Number(res.data.count);
                    }
                );
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val;
                this.userRechargeList();
            },
            currentChange(val) {
                this.page = val;
                this.userRechargeList();
            },
            //搜索
            seach(page) {
                this.page = page;
                this.userRechargeList(page);
            },
            reset(page) {
                this.search_id = "";
                this.page = page;
                this.userRechargeList(page);
            },

            // 添加选品
            add() {
                this.user_id = ""
                this.type = ''
                this.amount = ''
                this.remark = ''
                this.status = ''
                this.dialog = true;

            },
            // 修改、添加频道分类
            alter() {
                if (this.is_ok) {
                    this.$message({
                        message: '过于频繁操作，请稍后',
                        type: "error",
                    });
                    return
                }
                this.is_ok = true
                api.adduserRecharge({
                        user_id: this.user_id,
                        type: this.type,
                        amount: this.amount,
                        remark: this.remark,
                        status: this.status,
                    },
                    (res) => {
                        setTimeout(() => {
                            this.is_ok = false
                        }, 3000);
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        this.dialog = false;
                        this.userRechargeList();

                    }, (err) => {
                        this.is_ok = false
                    }
                );
            },
        },
    };
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>